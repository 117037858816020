$base-speed: 0.48s;
#backdoor {
	position: fixed;
	z-index: 1000;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	max-height: 0px;
	max-width: 0px;
	background-color: $primary;
	border-top-left-radius: 100%;
	border-bottom-right-radius: 10px;
	transition: max-height $base-speed, max-width $base-speed;
	box-shadow: inset 5px 5px 5px rgba(#000000, 0.7);

	// @supports (backdrop-filter: blur(25px)) {
	// 	background-color: rgba($primary, .8);
	// 	backdrop-filter: blur(12px);
	// }

	&.entrance {
		max-height: 80px;
		max-width: 80px;
	}

	&:hover {
		max-height: 220px;
		max-width: 220px;

		.backdoor-icon {
			max-height: 95px;
			max-width: 95px;
		}

		.action-item {
			animation: opacity $base-speed 1;
			animation-fill-mode: forwards;

			&.action-1 {
				right: 25px;
				bottom: 140px;
			}
			&.action-2 {
				right: 100px;
				bottom: 90px;
			}
			&.action-3 {
				right: 140px;
				bottom: 25px;
			}
		}
	}

	.backdoor-icon {
		position: absolute;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		max-height: 65px;
		max-width: 65px;
		transition: max-height $base-speed, max-width $base-speed;
	}

	.action-item {
		position: absolute;
		animation: opacity-back $base-speed 1;
		cursor: pointer;
		text-align: $dark;
		transition: opacity $base-speed, right $base-speed, bottom $base-speed;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
}

@keyframes opacity {
  0%   { opacity: 0; pointer-events: none;}
  15%   { opacity: 0; }
  100% { opacity: 1; pointer-events: auto;}
}
@keyframes opacity-back {
  0%   { opacity: 1; pointer-events: auto;}
  55% { opacity: 0;}
  100% { opacity: 0; pointer-events: none;}
}
