

input[type="radio"] {
  height: 0;
  opacity: 0;
  width: 0;

  &:checked {
    & ~ .slide {
      transform: translate3d(100%,0,0);
    }

    & + .slide {
      opacity: 1;
      transform: translate3d(0,0,0);
      z-index: 1;

      & + .nav {
        z-index: 2;
      }

      & ~ .slide{
        margin-left: 200px;
        transform: scale(0.8) translate3d(100%, 0, 0);
        right: 10%;
        margin-left: auto;
        opacity: 0.6;

        & ~ .slide{
          opacity: 0;
        }
      }
    }

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        & + .slide + .nav + .dots {
          .dot:nth-child(#{$i}) {
            background-color: #FFF;
          }
        }
      }
    }
  }
}

.slider {
  height: 100vh;
  position: relative;
  width: 100%;
	-webkit-overflow-scrolling: touch;
}

.slide {


element.style {
}
input[type=radio]:checked+.slide {
    opacity: 1;
    transform: translateZ(0);
    z-index: 1;
}
input[type=radio]:checked~.slide {
    transform: translate3d(100%,0,0);
}
.nav, .slide {
    position: absolute;
    z-index: 0;
    width: 100%;
}
.slide {
    height: 100%;
    top: 0;
    left: 0;
    transform: translate3d(-100%,0,0);
    transition: all 1s ease;
    max-width: 960px;
    opacity: 0;
    margin-left: 1rem;
}



  $colors: (tomato, dodgerblue, #9B59b6, #2ECC71);

}

.nav {
  padding: 20px;
  position: absolute;
    top: 50%;
  left: -90px;
  transform: translate3d(0, -50%, 0);
  width: 100%;
  z-index: 0;
}

.prev {
  cursor: pointer;
  float: left;

  &::after {

    display: block;
    height: 20px;
    width: 20px;
  }
}

.next {
  cursor: pointer;
  float: right;

  &::before {

    display: block;
    height: 20px;
    width: 20px;
  }
}

.dots {
  padding: 20px;
  position: absolute;
    bottom: 0;
    z-index: 1;
  text-align: center;
  width: 100%;
}

.dot {
  border: 2px solid $primary;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  width: 10px;
}
