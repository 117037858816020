.teaser-font-animation{
	ul{
		font-size: 5rem;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    text-align: right;
    justify-content: flex-end;
    position: absolute;
    transform: rotate(-4.5deg);
    top: 50%;
    left: -10%;
    color: rgb(255 255 255);

		li{


		}
	}
}
