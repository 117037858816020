.stage-one {

	.logo-box {
		height: 100vh;
	}

	.for-stage-two {
		display: none;
	}
}
