#footer-wrapper {

	.footer-box {
		// padding: 5rem;

	}

	.footer-siegel {
		img {
			margin: 1.875rem;
			width: auto;
			height: 75px;
			object-fit: contain;
		}
	}

	nav {

		.nav-link {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			text-transform: uppercase;

			&:hover, &:focus{
				color: $primary !important;
			}

			&.active {
				color: $primary !important;
			}
		}
	}

	.footer-copyright {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
		letter-spacing: 0px;

		padding-top: 20px;
		padding-bottom: 70px;
	}

}

.footer-header {
	color: #a5a5a5;
}
.footer-info {
	padding-bottom: 70px;
	@media screen and (min-width:536px){
		padding-bottom: 100px;
	}
}

.insta-link {
	&:hover {
		background: linear-gradient(60deg, #7f41fa,#d32e70);
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
.fb-link {
	&:hover {
		background-color: #4267B2;
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
