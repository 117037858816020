body{margin-top:20px;
	background-color: #e4e6e9;
	color: #41464d;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.btn-a-brc-tp:not(.disabled):not(:disabled).active, .btn-brc-tp, .btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle), .btn-h-brc-tp:hover, .btn.btn-f-brc-tp:focus, .btn.btn-h-brc-tp:hover {
	border-color: transparent;
}
.btn-outline-blue {
	color: #0d6ce1;
	border-color: #5a9beb;
	background-color: transparent;
}
.btn {
	cursor: pointer;
	position: relative;
	z-index: auto;
	border-radius: .175rem;
	transition: color .15s,background-color .15s,border-color .15s,box-shadow .15s,opacity .15s;
}
.border-2 {
	border-width: 2px!important;
	border-style: solid!important;
	border-color: transparent;
}
.bgc-white {
	background-color: #fff!important;
}


.text-green-d1 {
	color: #277b5d!important;
}
.letter-spacing {
	letter-spacing: .5px!important;
}
.font-bolder, .text-600 {
	font-weight: 600!important;
}
.text-170 {
	font-size: 1.7em!important;
}

.text-purple-d1 {
	color: #6d62b5!important;
}

.text-primary-d1 {
	color: #276ab4!important;
}
.text-secondary-d1 {
	color: #5f718b!important;
}
.text-180 {
	font-size: 1.8em!important;
}
.text-150 {
	font-size: 1.5em!important;
}
.text-danger-m3 {
	color: #e05858!important;
}
.rotate-45 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
.position-l {
	left: 0;
}
.position-b, .position-bc, .position-bl, .position-br, .position-center, .position-l, .position-lc, .position-r, .position-rc, .position-t, .position-tc, .position-tl, .position-tr {
	position: absolute!important;
	display: block;
}
.mt-n475, .my-n475 {
	margin-top: -2.5rem!important;
}
.ml-35, .mx-35 {
	margin-left: 1.25rem!important;
}

.text-dark-l1 {
	color: #56585e!important;
}
.text-90 {
	font-size: .9em!important;
}
.text-left {
	text-align: left!important;
}

.mt-25, .my-25 {
	margin-top: .75rem!important;
}

.text-110 {
	font-size: 1.1em!important;
}

.deleted-text{
text-decoration:line-through;;
}




















.price_plan_area {
    position: relative;
    z-index: 1;
    background-color: #f5f5ff;
}

.single_price_plan {
    position: relative;
    z-index: 1;
    border-radius: 0.5rem 0.5rem 0 0;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 50px;
    background-color: #ffffff;
    padding: 3rem 4rem;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_price_plan {
        padding: 3rem;
    }
}
@media only screen and (max-width: 575px) {
    .single_price_plan {
        padding: 3rem;
    }
}

.single_price_plan .title {
    text-transform: capitalize;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 2rem;
}
.single_price_plan .title span {
    color: #ffffff;
    padding: 0.2rem 0.6rem;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #2ecc71;
    display: inline-block;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}
.single_price_plan .title h3 {
    font-size: 1.25rem;
}
.single_price_plan .title p {
    font-weight: 300;
    line-height: 1;
    font-size: 14px;
}
.single_price_plan .title .line {
    width: 80px;
    height: 4px;
    border-radius: 10px;
    background-color: #3f43fd;
}
.single_price_plan .price {
    margin-bottom: 1.5rem;
}
.single_price_plan .price h4 {
    position: relative;
    z-index: 1;
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 0;
    color: #3f43fd;
    display: inline-block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    background-image: -webkit-gradient(linear, left top, right top, from(#e24997), to(#2d2ed4));
    background-image: linear-gradient(90deg, #e24997, #2d2ed4);
}
.single_price_plan .description {
    position: relative;
    margin-bottom: 1.5rem;
}
.single_price_plan .description p {
    line-height: 16px;
    margin: 0;
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
}
.single_price_plan .description p i {
    color: #2ecc71;
    margin-right: 0.5rem;
}
.single_price_plan .description p .lni-close {
    color: #e74c3c;
}
.single_price_plan.active,
.single_price_plan:hover,
.single_price_plan:focus {
    -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
    box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single_price_plan .side-shape img {
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    z-index: -2;
}

.section-heading h3 {
    margin-bottom: 1rem;
    font-size: 3.125rem;
    letter-spacing: -1px;
}

.section-heading p {
    margin-bottom: 0;
    font-size: 1.25rem;
}

.section-heading .line {
    width: 120px;
    height: 5px;
    margin: 30px auto 0;
    border-radius: 6px;
    background: #2d2ed4;
    background: -webkit-gradient(linear, left top, right top, from(#e24997), to(#2d2ed4));
    background: linear-gradient(to right, #e24997, #2d2ed4);
}
