$base-speed: .24s;

html {
	background: #3a3a3a;
}
html, body {
	position: relative;
	max-width: 100vw;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

// ::-webkit-scrollbar {
//   display: none;
// 	-ms-overflow-style: none;  /* IE and Edge */
// 	scrollbar-width: none;  /* Firefox */
// }

.cursor-pointer{
	cursor: pointer !important;
}

// @import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Mulish:200,300,400,500,600,700,800,900');
// font-face import
@import 'font-faces.scss';

body {
	// background: $primary;
	color: $secondary;
	font-family: 'Mulish', 'Noto Sans', sans-serif;

	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

a {
	display: inline-block;

	&.link-line {
		&:after{
			content: '';
			position: relative;
			display: block;
			width: 100%;
			height: .08rem;
			background: #fff;
			transform: rotate(1deg);
			transform-origin: 0 0;
			transition: transform .2s;
		}

		&:hover {
			text-decoration: none;

			&:after {
				transform: rotate(0deg);
			}
		}
	}
}

.opacity-75 {
	transition: opacity $base-speed linear;
	opacity: .75;
	&:hover {
		opacity: 1;
	}
}

button:focus{
	outline:none;
}

.btn-cta{
	background: #fff;
	color: $primary;
	box-shadow: 0 0 20px #f8f8f84a;
	padding: .5rem 2rem;
}

.tooltip {
	pointer-events: none;
	.tooltip-arrow, .tooltip-inner {
		background-color: $primary;
	}
	.arrow::before {
		border-left-color: $primary;
	}
}

.zindex-dropdown {
	z-index: $zindex-dropdown;
}

.top-0 {
	top: 0 !important;
}
.left-0 {
	left: 0 !important;
}
.right-0 {
	right: 0 !important;
}
.bottom-0 {
	bottom: 0 !important;
}
.width-screen {
	width: 100vw;
}
.height-screen {
	height: 100vh;
}

.bg-brand-solid {
	background: $primary;
}
.bg-brand-gradiant {
	background: rgb(248,249,250);
	background: linear-gradient(90deg, rgba(248,249,250,1) 0%, rgba(248,249,250,1) 70%, rgba(26,26,26,1) 70%);
}
.bg-brand-chess {
	background-color: $light;
	background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black),
		linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black);
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}
.bg-brand-image-1 {
	background-color: $light;
	background-image: url('/images/ui/bg_1.png');
	background-size: cover;
}
.bg-brand-image-2 {
	background-color: $light;
	background-image: url('/images/ui/bg_2.png');
	background-size: cover;
}

.logo-box {
	// transition: height 1s;

	.logo-img {
		max-width: 512px;
		width: 100%;
	}
}

.fadein-later {
	opacity: 0;
	animation: fadeinlater 1s;
	animation-delay: .24s;
	animation-fill-mode: forwards;
}
.fadein-later-1 {
	opacity: 0;
	animation: fadeinlater 1s;
	animation-delay: .48s;
	animation-fill-mode: forwards;
}
.fadein-later-2 {
	opacity: 0;
	animation: fadeinlater 1s;
	animation-delay: .72s;
	animation-fill-mode: forwards;
}
.fadein-now {
	opacity: 0;
	animation: fadein 1s;
	animation-delay: .4s;
	animation-fill-mode: forwards;
}

@keyframes fadein {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes fadeinlater {
	0% { margin-top: 4rem; opacity: 0; }
	100% { margin-top: 0rem; opacity: 1; }
}
@keyframes slideup {
	0% { height: 100vh }
	100% { height: 20vh; }
}

.card.card-secondary {
	color: $primary;
	background-color: rgba($secondary, 1);
	border: .5px solid rgba($secondary, .45);
	border-radius: $base-borer-radius * 2;
	box-shadow: 0 30px 60px rgba(26, 26, 26, 0.15);
	@supports (backdrop-filter: blur(25px)) {
		&.blured {
			background-color: rgba($secondary, .6);
			backdrop-filter: blur(25px);
		}
	}
}
.card.card-primary {
	color: $secondary;
	background-color: rgba($primary, 1);
	border: .5px solid rgba($primary, .6);
	border-radius: $base-borer-radius * 2;
	box-shadow: 0 30px 60px rgba(26, 26, 26, 0.25);
	@supports (backdrop-filter: blur(25px)) {
		&.blured {
			background-color: rgba($primary, .6);
			backdrop-filter: blur(25px);
		}
	}
}

@include media-breakpoint-down(md) {
	.container {
		max-width: none;
	}
}

.form-control {
	border-radius: 0;
	border: none;
	border-bottom: 2px solid $primary;

	&:focus, &:active, &.active {
		outline: none;
		box-shadow: none;
	}
}

// box-shadow animation
@keyframes shadow-animation {
	0% { box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175); }
	50% { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }
	100% { box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175); }
}

@-webkit-keyframes shadow-animation {
    0% { box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175); }
    50% { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175); }
    100% { box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175); }
}

.shadow-animation {
    -webkit-animation: shadow-animation 3.0s linear infinite;
    -moz-animation: shadow-animation 3.0s linear infinite;
    -ms-animation: shadow-animation 3.0s linear infinite;
    -o-animation: shadow-animation 3.0s linear infinite;
    animation: shadow-animation 3.0s linear infinite;
}

// img scale animation
.img-animation{
	-webkit-animation: img-animation 3.0s alternate infinite ease-in;
	-moz-animation: img-animation 3.0s alternate infinite ease-in;
	-ms-animation: img-animation 3.0s alternate infinite ease-in;
	-o-animation: img-animation 3.0s alternate infinite ease-in;
	animation: img-animation 3.0s alternate infinite ease-in;
}

@keyframes img-animation {
  0%   {transform: scale(1);}
	50%  {transform: scale(.99);}
  100% {transform: scale(1);}
}

@-webkit-keyframes img-animation {
  0%   {transform: scale(1);}
	50%  {transform: scale(.99d);}
  100% {transform: scale(1);}
}

.burstAway {
	animation: burstAway;
	animation-duration: .44s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
@keyframes burstAway {
	0% { filter: blur(0); opacity: 1; }
	100% { filter: blur(40px); opacity: 0; visibility: hidden; }
}

.wobbleAway {
	animation: wobbleAway;
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	transform-origin: right top;
}
@keyframes wobbleAway {
	0% { transform: rotate(0deg); opacity: 1; }
	90% { opacity: 1; }
	100% { transform: rotate(-180deg); opacity: 0; visibility: hidden; }
}

.w-sm-75 {
	@media screen and (min-width:536px){
		width: 75% !important
	}
}
.flex-xs-row {
	@media screen and (min-width:536px){
		flex-direction: row !important;
	}
}
