#cookie_consent_banner {
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: 10000;


	.cookie-content {
		background-color: $primary;
		/* slightly transparent fallback */
		/* if backdrop support: very transparent and blurred */
		// @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
		// 	background-color: rgba($primary, .75);
		// 	backdrop-filter: blur(4px) brightness(30%);
		// }

	}
}
