/* mulish-200 - latin */
@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 200;
	src: url('/fonts/mulish/Mulish-ExtraLight.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/fonts/mulish/Mulish-ExtraLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/fonts/mulish/Mulish-ExtraLight.woff2') format('woff2'), /* Super Modern Browsers */
		url('/fonts/mulish/Mulish-ExtraLight.woff') format('woff'), /* Modern Browsers */
		url('/fonts/mulish/Mulish-ExtraLight.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/fonts/mulish/Mulish-ExtraLight.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-300 - latin */
// @font-face {
// font-family: 'Mulish';
// 	font-style: normal;
// 	font-weight: 300;
// 	src: url('/fonts/mulish/Mulish-Light.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		url('/fonts/mulish/Mulish-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		url('/fonts/mulish/Mulish-Light.woff2') format('woff2'), /* Super Modern Browsers */
// 		url('/fonts/mulish/Mulish-Light.woff') format('woff'), /* Modern Browsers */
// 		url('/fonts/mulish/Mulish-Light.ttf') format('truetype'), /* Safari, Android, iOS */
// 		url('/fonts/mulish/Mulish-Light.svg#Mulish') format('svg'); /* Legacy iOS */
// }

/* mulish-regular - latin */
@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/mulish/Mulish-Regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/fonts/mulish/Mulish-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/fonts/mulish/Mulish-Regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('/fonts/mulish/Mulish-Regular.woff') format('woff'), /* Modern Browsers */
		url('/fonts/mulish/Mulish-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/fonts/mulish/Mulish-Regular.svg#Mulish') format('svg'); /* Legacy iOS */
}

// /* mulish-500 - latin */
// @font-face {
// font-family: 'Mulish';
// font-style: normal;
// font-weight: 500;
// src: url('/fonts/mulish/Mulish-Medium.eot'); /* IE9 Compat Modes */
// src: local(''),
// 		url('/fonts/mulish/Mulish-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		url('/fonts/mulish/Mulish-Medium.woff2') format('woff2'), /* Super Modern Browsers */
// 		url('/fonts/mulish/Mulish-Medium.woff') format('woff'), /* Modern Browsers */
// 		url('/fonts/mulish/Mulish-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
// 		url('/fonts/mulish/Mulish-Medium.svg#Mulish') format('svg'); /* Legacy iOS */
// }
/* mulish-600 - latin */
// @font-face {
// 	font-family: 'Mulish';
// 	font-style: normal;
// 	font-weight: 600;
// 	src: url('/fonts/mulish/Mulish-SemiBold.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		url('/fonts/mulish/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		url('/fonts/mulish/Mulish-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
// 		url('/fonts/mulish/Mulish-SemiBold.woff') format('woff'), /* Modern Browsers */
// 		url('/fonts/mulish/Mulish-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
// 		url('/fonts/mulish/Mulish-SemiBold.svg#Mulish') format('svg'); /* Legacy iOS */
// }

/* mulish-700 - latin */
@font-face {
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/mulish/Mulish-Bold.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('/fonts/mulish/Mulish-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('/fonts/mulish/Mulish-Bold.woff2') format('woff2'), /* Super Modern Browsers */
		url('/fonts/mulish/Mulish-Bold.woff') format('woff'), /* Modern Browsers */
		url('/fonts/mulish/Mulish-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
		url('/fonts/mulish/Mulish-Bold.svg#Mulish') format('svg'); /* Legacy iOS */
}
/* mulish-800 - latin */
// @font-face {
// 	font-family: 'Mulish';
// 	font-style: normal;
// 	font-weight: 800;
// 	src: url('/fonts/mulish/Mulish-ExtraBold.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		url('/fonts/mulish/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		url('/fonts/mulish/Mulish-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
// 		url('/fonts/mulish/Mulish-ExtraBold.woff') format('woff'), /* Modern Browsers */
// 		url('/fonts/mulish/Mulish-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
// 		url('/fonts/mulish/Mulish-ExtraBold.svg#Mulish') format('svg'); /* Legacy iOS */
// }
/* mulish-900 - latin */
// @font-face {
// 	font-family: 'Mulish';
// 	font-style: normal;
// 	font-weight: 900;
// 	src: url('/fonts/mulish/Mulish-Black.eot'); /* IE9 Compat Modes */
// 	src: local(''),
// 		url('/fonts/mulish/Mulish-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
// 		url('/fonts/mulish/Mulish-Black.woff2') format('woff2'), /* Super Modern Browsers */
// 		url('/fonts/mulish/Mulish-Black.woff') format('woff'), /* Modern Browsers */
// 		url('/fonts/mulish/Mulish-Black.ttf') format('truetype'), /* Safari, Android, iOS */
// 		url('/fonts/mulish/Mulish-Black.svg#Mulish') format('svg'); /* Legacy iOS */
// }
