.showcase-carousel{

	.owl-item {;}
.owl-item.active {height: auto;}
	.owl-item{



		.item{
			max-width: 1400px;
			height: auto !important;
			max-height: 500px;
		}
	}

	.owl-nav{
		button{
			opacity: 1;
			top: 49%;
			position: absolute;
			height: 100%;
			text-align: center;
			-moz-user-select: none;
			user-select: none;
			-ms-user-select: none;
			-webkit-user-select: none;
			line-height: 66px;
			cursor: pointer;
			transform: translateY(-50%);

			&.owl-next {
			 right: -5px;
			 border-radius: 0.25rem 0 0 0.25rem !important;
			 width: 9%;
			}

			&.owl-prev{
			 left: 15px;
			 border-radius: 0 0.25rem 0.25rem 0 !important;
			 transition: opacity 0.2s ease-in-out;
			}
		}
	}

}

.tiles {
	--tiles-height: 52vw;
	height: var(--tiles-height);
	position: relative;
	overflow: hidden;
}

.tiles--columns-rotated {
	--tiles-height: 180vmax;
}

.tiles--columns {
	--tiles-height: 140vh;
}

.tiles--oneline {
	--tiles-height: 50vh;
	min-height: 400px;
	margin: 10vh 0;
}

.tiles--small {
	--tiles-height: 100px;
	margin: 10vh 0 0;
}

.tiles--fixed {
	--tiles-height: 65vw;
	margin-top: 25vh;
}

.tiles--perspective {
	overflow: visible;
	perspective: 1000px;
	--tiles-height: 500px;
}

.tiles__wrap {
	width: 150%;
	--tileswrap-height: var(--tiles-height);
	height: var(--tileswrap-height);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%,-50%, 0);
}

.tiles--rotated .tiles__wrap {
	--tileswrap-height: calc(var(--tiles-height) * 1.8);
	transform: translate3d(-50%,-50%, 0) rotate(22.5deg);
}

.tiles--columns-rotated .tiles__wrap {
	width: 150%;
	display: flex;
	transform: translate3d(-50%,-50%,0) rotate(-22.5deg);
	left: 50%;
	top: 50%;
	align-items: center;
	justify-content: center;
}

.tiles--columns .tiles__wrap {
	width: 100%;
	display: flex;
	--tileswrap-height: calc(var(--tiles-height) * 1.4);
	transform: translate3d(0,-50%,0);
	left: 0;
	top: 50%;
}

.tiles--perspective .tiles__wrap {
	width: 200%;
    transform: translate3d(-50%,-50%,0) translateX(-25%) translateZ(-1200px) rotateX(75.5deg) rotateZ(12deg);
}

.tiles--fixed .tiles__wrap {
	width: 100%;
}

.tiles__line {
	display: flex;
}

.tiles--columns .tiles__line {
	width: 25%;
	padding: 0 1rem;
	display: block;
}

.tiles--columns-rotated .tiles__line {
	width: 320px;
	flex: none;
	padding: 0 1.5vw;
	display: block;
}

.tiles--columns-rotated .tiles__line:nth-child(even) {
	margin-top: -160px;
}

.tiles--oneline .tiles__line {
	height: 100%;
}

.tiles--small .tiles__line {
	height: 100%;
}

.tiles__line-img {
	--tile-margin: 2vw;
	flex: none;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
	margin: var(--tile-margin);
	background-size: cover;
	background-position: 50% 50%;
}

.tiles--perspective .tiles__line-img {
	backface-visibility: hidden;
	outline: 1px solid transparent;
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc(16.666vw * 1.3);
}

.tiles--oneline .tiles__line-img {
	--tile-margin: 1vw;
	margin: 0 var(--tile-margin);
	width: calc(25% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--small .tiles__line-img {
	--tile-margin: 5px;
	margin: 0 var(--tile-margin);
	width: calc(12.5% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--rotated .tiles__line-img {
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 5 - (4 * var(--tile-margin) / 2));
}

.tiles--columns-rotated .tiles__line-img {
	--tile-margin: 3vw 0;
	width: 100%;
	height: 400px;
}

.tiles--columns .tiles__line-img {
	--tile-margin: 2rem 0;
	width: 100%;
	height: calc(25vw * 1.3);
}

.tiles--fixed .tiles__line-img {
	--tile-margin: 10px;
	-webkit-filter: brightness(0.8);
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 3 - (3 * var(--tile-margin) / 2));
}

.tiles--darker .tiles__line-img {
	opacity: 0.7;
}

.tiles__title {
	position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10vw;
	padding: 3rem;
	margin: 0;
	line-height: 0.8;
	font-family: span, serif;
	font-weight: 700;
	font-style: normal;
}

.tiles__title--alt {
	font-size: 9vw;
	font-size: clamp(2rem, 9vw, 7.25rem);
}

.tiles__title--intro {
	padding-top: 10vh;
	align-items: flex-start;
	justify-content: flex-start;
}

.tiles__title--right {
	justify-content: flex-end;
}

.tiles__title--left {
	justify-content: flex-start;
}

.tiles__title--full {
	height: 100%;
}
