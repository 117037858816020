// App Scss
$grid-breakpoints: (
	xs: 0,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1380px
);

$primary:       #1a1a1a !default;
$secondary:     lighten(#1a1a1a, 75%) !default;

$main_brand: #5D5D5D;
$main_brand_bg: #c1c3c5;
$sub_brand: #523800;

$base-borer-radius: 4px;

// Bootstrap
@import "~bootstrap/scss/bootstrap";
// owl.Carousel
@import "~owl.carousel/dist/assets/owl.carousel";

// Fontawesome
// $fa-font-path: "~@fortawesome/fontawesome-pro/" !default;
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/brands.scss";
@import "~@fortawesome/fontawesome-pro/scss/duotone.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";


// Custom Styles
@import "base-styles";

// layouts
@import "layout/header_wrapper";
@import "layout/footer_wrapper";

// modules
// @import "layout/modules/main_grid";
@import "layout/modules/main_header";
@import "layout/modules/content_header";
@import "layout/modules/site_block";
@import "layout/modules/backdoor";
@import "layout/modules/customer_listing";
@import "layout/modules/form";
@import "layout/modules/form_header";
@import "layout/modules/services-slider";
@import "layout/modules/stage-one";
@import "layout/modules/stage-two";
@import "layout/modules/teaser/teaser-font-animation";
@import "layout/modules/site_animation";
@import "layout/modules/owl-carousel";
@import "layout/modules/cookies";
@import "layout/modules/studio-pricing";
