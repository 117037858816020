.ear-block {
	display: block;
	height: 200px;
	width: 100%;

	&.right {
		clip-path: polygon(0 100px, 100% 0, 100% 100%, 0% 100%);
	}
	&.left {
		clip-path: polygon(0 0, 100% 100px, 100% 100%, 0% 100%);
	}
}

.clip-path-bottom-right-ear {
	clip-path: polygon(0 0, 100% 0, 100% 75%, 63% 100%, 0 100%);
}
.clip-path-top-right-ear {
	clip-path: polygon(0 20%, 75% 20%, 100% 0, 100% 100%, 0 100%);
}
.triangle-parent {
	position: absolute;
	left: 25%;
	top: -95px;
	filter: drop-shadow(0 30px 60px rgba($primary, .5));
	z-index: 10;

	img{
		max-width: 250px;
	}

	// .triangle-element {
	// 	position: absolute;
	// 	height: 80px;
	// 	width: 100px;
	// 	clip-path: polygon(50% 100%, 0 0, 100% 0);
	// }
}

.site_block {

	&.min-h-80{
		min-height: 80vh;
	}

	img.collection-bg {
		position: absolute;
		pointer-events: none;
		user-select: none;
		height: 950px;
		width: auto;
		// z-index: 100;
		left: -680px;
		top: -250px;

		@include media-breakpoint-down(sm) {
			height: 600px;
			left: -350px;
		}

		@include media-breakpoint-down(xs) {
			height: 430px;
			left: -150px;
			top: -150px;
		}
	}
	img.studioteaser {
		position: absolute;
		pointer-events: none;
		user-select: none;
		height: 850px;
		width: auto;
		// z-index: 100;
		left: -280px;
		top: -200px;

		@include media-breakpoint-down(sm) {
			height: 600px;
			left: -350px;
		}

		@include media-breakpoint-down(xs) {
			height: 430px;
			left: -150px;
			top: -150px;
		}
	}
	img.poin_rectangle.right {
		position: absolute;
		pointer-events: none;
		user-select: none;
		transform: rotate(45deg);
		// z-index: 100;
		right: -148px;
		top: 300px;

		// filter: blur(5px);
		// opacity: 0.8;
		@include media-breakpoint-down(md) {
			max-width: 200px;
			top: auto;
			bottom: -150px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 150px;
			top: auto;
			bottom: -90px;
			z-index: 1;
		}
	}
	img.poin_rectangle.rightbottom {
		position: absolute;
		pointer-events: none;
		user-select: none;
		transform: rotate(-45deg);
		z-index: 100;
		right: -148px;
		bottom: -155px;

		// filter: blur(5px);
		// opacity: 0.8;

		@include media-breakpoint-down(md) {
			max-width: 200px;
		}

		@include media-breakpoint-down(sm) {
			max-width: 150px;
		}
	}

	img.poin_rectangle.leftcenter {
		position: absolute;
		pointer-events: none;
		user-select: none;
		// z-index: 100;
		transform: rotate(39deg);
		left: -10px;
		bottom: -60px;
		// filter: blur(3px);
	}

	.site_block-content {

		.info-header {
			// border-radius: $base-borer-radius * 2;
			// box-shadow: 0 30px 60px rgba(#1A1A1A, 0.15);
			// border: .5px solid rgba(#1A1A1A, 0.15);

			@include media-breakpoint-down(xs) {
				margin-top: 10rem;
			}

			@supports (backdrop-filter: blur(25px)) {
				&.blured {
					background-color: rgba($secondary, .2);
					backdrop-filter: blur(25px);
					@include media-breakpoint-down(md) {
						background-color: rgba($secondary, .6);
					}
				}
			}

			div {
				line-height: .8;
			}
		}

		.info-text-icon {
			display: inline-block;
			position: relative;
			width: 30px;
			height: 5px;
			background: #1a1a1a;
			top: -5px;
		}

		.info-text-icon-vertical {
			display: block;
			position: relative;
			width: 5px;
			height: 150px;
			background: #1a1a1a;
			top: 25px;
		}

		.imbasto-services{
			background: $primary;

			@include media-breakpoint-down(sm) {
				background: transparent;
			}
			.owl-carousel {
				.imbasto-service-block {
					display: block;
					// width: 97%;

					.card-body {
						opacity: 1;
					}
				}
				.owl-stage-outer{
					padding-top: 2.2rem;
					padding-bottom: 3rem;
				}
			}
			.imbasto-service-block {
				display: none;
				// width: 92%;

				.card-body{
					min-height: 445px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					opacity: 0;
					-webkit-animation: opacity 500ms ease-in-out; /* Chrome, Safari, Opera */
					animation: opacity 500ms ease-in-out;
				}

				&.active {
					display: block;

					.card-body{
						opacity: 1;
					}

				}
			}
		}


		.imbasto-service-switch {

			&:focus{
				box-shadow: none;
			}

			&.active {
				background-color: $primary;
				color: $light;

			}

			@include media-breakpoint-down(xs) {
				font-size: 83%;
			}

		}
	}

	@include media-breakpoint-up(md) {
		.float-card {
			// width: calc(100% + 250px);
			// margin-left: -240px;
			width: calc(100% + 170px);
			margin-left: -120px;
		}
	}
	@include media-breakpoint-up(md) {
		.overlapping-card {
			position: absolute;
			width: calc(100% - 2rem);
		}
	}
	@include media-breakpoint-down(md) {
		.overlapping-card {
			margin-bottom: -260px;
		}
	}
}
