.content-header {
	min-height: 700px;

	img.main_header-brand {
		height: 140px;
		top: -60px;
		position: relative;
	}
	img.poin_rectangle {
		position: absolute;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: rotate(45deg);
    z-index: 100;
    left: -550px;
    top: -122px;
	}

	&:hover .perspective-text .perspective-line p {
		transform: translate(0, -50px);
	}

	.perspective-text {
		pointer-events: none;
		user-select: none;
		color: $secondary;
		font-family: Arial;
		font-size: 38px;
		font-weight: 900;
		letter-spacing: -2px;
		text-transform: uppercase;
		transform: translate(150px, -110px);

		.small {
			font-size: 22px;
		}

		.perspective-line {
			height: 50px;
			overflow: hidden;
			position: relative;

			p {
				margin: 0;
				height: 50px;
				line-height: 50px;
				transition: transform 0.5s ease-in-out;
				text-shadow: -5px -5px 5px #00000052;
			}

			&:nth-child(odd) {
				transform: skew(60deg, -30deg) scaleY(0.667);
			}
			&:nth-child(even) {
				transform: skew(0deg, -30deg) scaleY(1.333);
			}

			&:nth-child(1) {
				left: 29px;
			}
			&:nth-child(2) {
				left: 58px;
			}
			&:nth-child(3) {
				left: 87px;
			}
			&:nth-child(4) {
				left: 116px;
			}
			&:nth-child(5) {
				left: 145px;
			}
		}
	}

	.teaser-text, .perspective-text {
		.line-1 {
			font-size: calc(2rem * 1.2);
		}
		.line-2 {
			font-size: calc(2rem * 1.8);
		}
		.line-3 {
			font-size: calc(2rem * 1.8);
		}
	}
	@include media-breakpoint-down(xs) {
		.teaser-text, .perspective-text  {
			.line-1 {
				font-size: calc(2rem * .8);
			}
			.line-2 {
				font-size: calc(2rem * 1.2);
			}
			.line-3 {
				font-size: calc(2rem * 1.2);
			}
		}
	}
}


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
