#header-wrapper {
	height: 77.5px;
	overflow: hidden;
	padding-top: 15px;
	padding-bottom: 15px;

	.logo {
		display: block;
		height: 100%;

		img {
			width: auto;
			max-height: 100%;
		}

	}

	nav {

		.nav-link {
			font-size: 15px;
			font-weight: 400;
			letter-spacing: 0px;

			&:hover, &:focus{
				color: $secondary !important;
			}

			&.active {
				color: $secondary !important;
			}
		}

	}

}

@include media-breakpoint-down(md) {

	#header-wrapper {

		nav {
			position: absolute;
			top: 78px;
			bottom: 0;
			left: 100%;
			width: 100%;
			height: calc( 100vh - 78px );
			padding-right: 1rem;
			padding-left: 1rem;
			overflow-y: auto;
			visibility: hidden;
			background-color: #fff;
			transition-timing-function: ease-in-out;
			transition-duration: .3s;
			transition-property: left, visibility;
			z-index: 100;

			&.open {
				left: 0;
				visibility: visible;
			}

			.nav-link {
				font-size: 25px;
			}

		}


		.navbar-toggle {
			display: block;
			position: relative;
			cursor: pointer;
			z-index: 1;

			-webkit-user-select: none;
			-moz-user-select: none;
			 -ms-user-select: none;
					 user-select: none;

			.lines {
				display: block;
				position: absolute;
				width: 33px;
				height: 4px;
				margin-bottom: 5px;
				position: relative;
				background: $secondary;
				border-radius: 3px;

				z-index: 1;
				transform-origin: 4px 0px;
				-webkit-transform-origin: 4px 0px;
				transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
				transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
				transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

				&:first-child {
					-webkit-transform-origin: 0% 0%;
					transform-origin: 0% 0%;
				}

				&:last-child {
					-webkit-transform-origin: 0 100%;
					transform-origin: 0% 100%;
				}
			}

			&.open {

				.lines {
					opacity: 1;
					-webkit-transform: rotate(45deg) translate(-4px, -3px);
					transform: rotate(45deg) translate(-4px, -3px);
					background: $primary;

					&:nth-last-child(2) {
						opacity: 0;
						-webkit-transform: rotate(0deg) scale(0.2, 0.2);
						transform: rotate(0deg) scale(0.2, 0.2);
					}

					&:last-child {
						-webkit-transform: rotate(-45deg) translate(0, -1px);
						transform: rotate(-45deg) translate(0, -1px);
					}
				}

			}

		}

	}

}
