.contact-form{

	.form-group {
		position: relative;
		margin: auto;
		width: 100%;
		overflow: hidden;

		input, textarea {
			-webkit-appearance: none !important;
			-moz-appearance: none;
			appearance: none !important;
			width: 100%;
			border: 0;
			border-radius: 0 !important;
			font-family: inherit;
			padding: 22px 12px 0 9px;
			height: 56px;
			font-weight: 400;
			box-shadow: inset 0 -1px 0 rgba(#1E1C24, 0.3) !important;
			color: #1E1C24;
			-webkit-transition: all 0.15s ease;
			transition: all 0.15s ease;
			background: transparent;

			&::placeholder{
				-webkit-appearance: none !important;
				-moz-appearance: none;
				appearance: none !important;
				visibility: hidden;
			}

			&:hover {
				box-shadow: inset 0 -1px 0 rgba(#1E1C24, 0.5);
			}

			&:not(:placeholder-shown) + label {
				color: #585858;
				-webkit-transform: translate3d(0, -12px, 0) scale(0.75);
				transform: translate3d(0, -12px, 0) scale(0.75);
			}

			&:focus {
				outline: none;
				box-shadow: inset 0 -2px 0 #1E1C24;

				+ label {
					color: #1E1C24;
					-webkit-transform: translate3d(0, -12px, 0) scale(0.75);
					transform: translate3d(0, -12px, 0) scale(0.75);

					+ .focus-bg {
					-webkit-transform: scaleX(1);
					transform: scaleX(1);
					-webkit-transition: all 0.1s ease;
					transition: all 0.1s ease;
					}
				}
			}
		}

		textarea{
			height: auto;
		}

		label {
			position: absolute;
			top: 20px;
			left: 12px;
			color: #585858;
			font-weight: 500;
			-webkit-transform-origin: 0 0;
			transform-origin: 0 0;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
			pointer-events: none;
		}


		.focus-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.05);
			z-index: -1;
			-webkit-transform: scaleX(0);
			transform: scaleX(0);
			-webkit-transform-origin: left;
			transform-origin: left;
		}

	}
}


.project_form{

  .step_indicators {
    display: block;
    position: relative;
    text-align: center;

    .step, .line{
      display: inline-block;
      font-size: 150%;
      border-radius: 50%;
      width: 25px;
      height: 35px;
      text-align: center;
      opacity: .5;
      transition: all .24s;

      &.current {
        opacity: 1;
        background-color: $secondary;
        border: .5px solid lighten($secondary, 20%);
        box-shadow: 0 3px 6px rgba(#000000, 0.4);
      }

      &.final.current {
        background-color: #70B037;
        border: .5px solid lighten(#70B037, 20%);
      }
    }

    .line {
      width: 60px;
      margin: 0 10px;
      height: 6px;
      background-color: darken($primary, 10%);
      border: .5px solid darken($primary, 15%);
      border-radius: 3px;
      position: relative;
      top: -10px;

      &.current {
        opacity: .8;
        background-color: darken($primary, 10%);
        border: .5px solid darken($primary, 15%);
        box-shadow: none;
      }
    }

  }

}
