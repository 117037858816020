.stage-two {

	.logo-box {
		min-height: 200px;
		height: 100vh;
		animation: slideup 1s;
		animation-delay: .5s;
		animation-fill-mode: forwards;
	}


	.text-light-later {
		transition: color .24s ease-in-out;
		transition-delay: 1s;
		color: $primary;
	}
}
