.customer-list{
	li{
		img{
		//	max-width: 180px;
		}
	}
}

@include media-breakpoint-down(xs) {
	.customer-list{
		li{
			img{
				max-width: 140px;
			}
		}
	}
}
@media screen and (max-width:374px) {
	.customer-list{
		li{
			img{
				max-width: 130px;
			}
		}
	}
}
@media screen and (max-width:364px) {
	.customer-list{
		li{
			img{
				max-width: 120px;
			}
		}
	}
}
