.main-content{

	.st-content {
		overflow-y: scroll;
		background: #f3efe0;
	}

	.st-content,
	.st-content-inner {
		position: relative;
	}


		position: relative;
		overflow: hidden;


	.site-animation-pusher {
		position: relative;
		left: 0;
		z-index: 99;
		height: 100%;
		perspective: 1000px;
		-webkit-transition: -webkit-transform 0.5s;
		transition: transform 0.5s;
	}

	.site-animation::after {
		position: absolute;
		top: 0;
		right: 0;
		width: 0;
		height: 0;
		background: rgba(0,0,0,0.2);
		content: '';
		opacity: 0;
		-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	}

	.site-animation-open .site-animation::after {
		width: 100%;
		height: 100%;
		opacity: 1;
		-webkit-transition: opacity 0.5s;
		transition: opacity 0.5s;
	}

	.header-form {
		position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 100;
visibility: hidden;
width: 100%;
transition: all 0.5s;
	}

	.header-form::after {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
		content: '';
		opacity: 1;
		-webkit-transition: opacity 0.5s;
		transition: opacity 0.5s;
	}

	.site-animation-open .header-form::after {
		width: 0;
		height: 0;
		opacity: 0;
		-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
		transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	}


	.header-form ul li a {
		display: block;
		padding: 1em 1em 1em 1.2em;
		outline: none;
		box-shadow: inset 0 -1px rgba(0,0,0,0.2);
		color: #f3efe0;
		text-transform: uppercase;
		text-shadow: 0 0 1px rgba(255,255,255,0.1);
		letter-spacing: 1px;
		font-weight: 400;
		-webkit-transition: background 0.3s, box-shadow 0.3s;
		transition: background 0.3s, box-shadow 0.3s;
	}

	.header-form ul li:first-child a {
		box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
	}

	.header-form ul li a:hover {
		background: rgba(0,0,0,0.2);
		box-shadow: inset 0 -1px rgba(0,0,0,0);
		color: #fff;
	}
}
.site-animation-open .site-animation-pusher {
	-webkit-transform: translate3d(0, 500px, 0);
	transform: translate3d(0, 500px, 0);
}

.header-form {
	z-index: 1;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

.site-animation-open .header-form {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.header-form::after {
	display: none;
}
